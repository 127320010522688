export const THEME_MODE = 'THEME_MODE';
export const USER_PROFILE = 'USER_PROFILE';
export const SECONDARY_PROJ_FLAG = 'SECONDARY_PROJ_FLAG';
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS';
export const GET_ALL_EMPLOYEES = 'GET_ALL_EMPLOYEES';
export const GET_SINGLE_EMPLOYEE = 'GET_SINGLE_EMPLOYEE';
export const GET_MY_TICKET = 'GET_MY_TICKET';
export const UPDATE_TICKET_DESCRIPTION = 'UPDATE_TICKET_DESCRIPTION';

export const REMOVE_PROJECT = 'REMOVE_PROJECT';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_PROJECTS = 'ADD_PROJECTS';
export const PROJECTS_TABLE_ERROR = 'PROJECTS_TABLE_ERROR';
export const CLEAR_PROJECTS_TABLE_ERROR = 'CLEAR_PROJECTS_TABLE_ERROR';
export const SET_PROJECT_FILTER = 'SET_PROJECT_FILTER';
export const RESET_PROJECT_FILTERS = 'RESET_PROJECT_FILTERS';
export const SET_RESOURCE_FILTER = 'SET_RESOURCE_FILTER';
export const RESET_RESOURCE_FILTERS = 'RESET_RESOURCE_FILTERS';

export const SET_NAVIGATION_BAR = 'SET_NAVIGATION_BAR';
export const EMPLOYEEINFO = 'EMPLOYEEINFO';

export const USER_PERMISSION_MODAL = 'USER_PERMISSION_MODAL';
export const EMPLOYEE_PERMISSION_INFO = 'EMPLOYEE_PERMISSION_INFO';
export const ALL_EMPLOYEES_PERMISSIONS = 'ALL_EMPLOYEES_PERMISSIONS';

export const GET_USER_PERMISSIONS = 'GET_USER_PERMISSIONS';
export const FETCH_USER_PERMISSIONS = 'FETCH_USER_PERMISSIONS';
export const REMARKS_MODAL_FLAG = 'REMARKS_MODAL_FLAG';

export const ADD_PM_IN_DIV = 'ADD_PM_IN_DIV';
export const REV_PM_FROM_DIV = 'REV_PM_FROM_DIV';
export const ERROR_PM_DIV = 'ERROR_PM_DIV';

// Board Lane Types
export const GET_CARDS_INFO = 'GET_CARDS_INFO';
export const CREATE_BOARD_LANE = 'CREATE_BOARD_LANE';
export const UPDATE_BOARD_LANE = 'UPDATE_BOARD_LANE';
export const DELETE_BOARD_LANE = 'DELETE_BOARD_LANE';

// Board Card Types
export const CREATE_CARD = 'CREATE_CARD';
export const UPDATE_CARD = 'UPDATE_CARD';
export const DELETE_CARD = 'DELETE_CARD';

export const CREATE_COMMENT = 'CREATE_COMMENT';
export const GET_CARD_COMMENTS = 'GET_CARD_COMMENTS';
export const UPDATE_COMMENT = 'UPDATE_COMMENT';
export const DELETE_COMMENT = 'DELETE_COMMENT';

export const VIEW_IMAGE_LIGHTBOX = 'VIEW_IMAGE_LIGHTBOX';

export const PM_DETAILS_MODAL = 'PM_DETAILS_MODAL';
export const CV_DETAILS = 'CV_DETAILS';
export const LOADING_ACTION = 'LOADING_ACTION';

// Home
export const DEVELOPERS = 'DEVELOPERS';

// Skill Engagement
export const SkILLS = 'SkILLS';
export const CREATE_SKILL = 'CREATE_SKILL';
export const DELETE_SKILL = 'DELETE_SKILL';
export const SKILL_INPUT = 'SKILL_INPUT';
export const SEARCH_INPUT = 'SEARCH_INPUT';
export const CLEAR = 'CLEAR';

export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS';
export const CONCAT_USER_NOTIFICATIONS = 'CONCAT_USER_NOTIFICATIONS';
export const RESET_USER_NOTIFICATIONS = 'RESET_USER_NOTIFICATIONS';
export const READ_USER_NOTIFICATION = 'READ_USER_NOTIFICATION';
export const SET_USER_NOTIFICATION = 'SET_USER_NOTIFICATION';
export const RESET_USER_NOTIFICATION = 'RESET_USER_NOTIFICATION';

// Pubic Holidays
export const GET_HOLIDAYS = 'GET_HOLIDAYS';

// Policy Management
export const ADD_POLICY = 'ADD_POLICY';
export const DELETE_POLICY = 'DELETE_POLICY';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const SET_ACTIVE_POLICY = 'SET_ACTIVE_POLICY';
export const GET_POLICY_LIST = 'GET_POLICY_LIST';
export const GET_POLICY_HISTORY = 'GET_POLICY_HISTORY';
export const GET_SINGLE_POLICY = 'GET_SINGLE_POLICY';

// Routes
export const SET_LAST_ROUTE = 'SET_LAST_ROUTE';

// Encashment And Carryforward Leaves
export const GET_LEAVES_ENCASHMENT_AND_CARRY_FORWARD_DETAILS = 'GET_LEAVES_ENCASHMENT_AND_CARRY_FORWARD_DETAILS';
export const MAKE_LEAVES_ENCASHMENT_REQUEST = 'MAKE_LEAVES_ENCASHMENT_REQUEST';
