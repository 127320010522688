import { GET_LEAVES_ENCASHMENT_AND_CARRY_FORWARD_DETAILS } from '../actions/Types';

const initialState = {
  details: [],
};

const encashmentAndCarryforwardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LEAVES_ENCASHMENT_AND_CARRY_FORWARD_DETAILS:
      return {
        ...state,
        details: action.payload,
      };

    default:
      return { ...state };
  }
};

export default encashmentAndCarryforwardReducer;
