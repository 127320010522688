import { combineReducers } from 'redux';
import auth from './auth';
import navbar from './navbar';
import layout from './layout';
import { userReducer, userPermissionReducer } from './users.reducer';
import { policyReducer } from './policies.reducer';
import settings from './settings.reducer';
import feedback from './feedback.reducer';
import ratings from './ratings.reducer';
import { divisionsReducer, projectsErrorReducer } from './projects.reducer';
import { boardReducer, boardImagesPreviewReducer } from './board.reducer';
import audits from './audit.reducer';
import portfolioReducer from './portfolio.reducer';
import { flagReducer, userPermissionModal, remarksModalReducer, PMDetailsModalReducer } from './modal.reducer';
import { employeesReducer, employeeInfoReducer } from './employees.reducer';
import graphs from './graph.reducer';
import currentEmployeeData from './currentEmployee.data.reducer';
import { employeePermissionInfo, employeeWithPermissions } from './permissions.reducer';
import cvReducer from './cv.reducer';
import toolsReducer from './tool.reducer';
import ticketsReducer from './tickets.reducer';
import upselloppurtunityReducer from './upselloppurtunity.reducer';
import onBoardingProjectReducer from './onBoardingProject.reducer';
import resumeBuilderReducer from './resume/index';
import homeReducer from './home.reducer';
import skillsReducer from './skills.reducer';
import { columnModalReducer, columnReducer, columnMainReducer } from './columnModal.reducer';
import monthlyAuditForm from './monthlyAuditForm.reducer';
import emailMarketingDomains from './emailMarketingDomains.reducer';
import { departments } from './departments.reducer';
import { designations } from './designations.reducer';
import { designationLevels } from './designationLevels.reducer';
import { webhookReducers } from './webhooks.reducer';
import {
  billingStatus,
  companyDivision,
  engineeringDivision,
  seniorityDivision,
  offBoardingDetails,
  onBoardingDetails,
  headCount,
} from './executiveSummary.reducer';
import notificationsReducer from './notifications.reducer';
import holidaysReducer from './publicHoliday.reducer';
import claimsReducer from './claim.reducer';

import { evaluations, processUpdate, myComponent, getReviews } from './resourceEvaluation.reducer';
import { loans } from './loan.reducer';
import getSkillsRequest from './elSkillsRequest.reducer';
import providentFundReducer from './providentFund.reducer';
import { positionOpenings } from './positionOpening.reducer';
import routeHistoryReducer from './routeHistory.reducer';
import navigationReducer from './navigation.reducer';
import offlineTimeRequestsReducer from './offlineTime.reducer';
import employeePipsReducer from './employeePip.reducer';
import encashmentAndCarryforwardReducer from './encashmentAndCarryforward.reducer';

const rootReducer = combineReducers({
  auth,
  currentEmployeeData,
  navbar,
  layout,
  users: userReducer,
  policies: policyReducer,
  settings,
  feedback,
  ratings,
  projects: divisionsReducer,
  projectsErrors: projectsErrorReducer,
  audits,
  modalFlag: flagReducer,
  userPermissionModal,
  remarksModal: remarksModalReducer,
  graphs,
  skills: skillsReducer,
  employees: employeesReducer,
  employeeInfo: employeeInfoReducer,
  board: boardReducer,
  boardImagesPreview: boardImagesPreviewReducer,
  employeePermissionInfo,
  employeeWithPermissions,
  userPermissions: userPermissionReducer,
  pmDetailsModal: PMDetailsModalReducer,
  cv: cvReducer,
  tools: toolsReducer,
  tickets: ticketsReducer,
  portfolio: portfolioReducer,
  upselloppurtunity: upselloppurtunityReducer,
  onBoardingProject: onBoardingProjectReducer,
  resumeBuilder: resumeBuilderReducer,
  developers: homeReducer,
  columnModal: columnModalReducer,
  columnReducer,
  columnMainReducer,
  monthlyAuditForm: monthlyAuditForm,
  emailMarketingDomains: emailMarketingDomains,
  billingStatus,
  companyDivision,
  engineeringDivision,
  seniorityDivision,
  offBoardingDetails,
  onBoardingDetails,
  headCount,
  notificationsReducer,
  evaluations,
  processUpdate,
  myComponent,
  getReviews,
  getSkillsRequest,
  departments,
  holidaysReducer,
  designations,
  designationLevels,
  claimsReducer,
  loans,
  providentFund: providentFundReducer,
  webhook: webhookReducers,
  positionOpenings: positionOpenings,
  routeHistory: routeHistoryReducer,
  navigation: navigationReducer,
  offlineTimeRequestsReducer: offlineTimeRequestsReducer,
  employeePipsReducer,
  encashmentAndCarryforwardReducer,
});

export default rootReducer;
